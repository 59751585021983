import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { selectAllProperties } from '../../../core/+state/core.reducer';
import { combineProperties } from '../../../core/helpers';
import { IProperty } from '../../../features/commons/properties/models/property.model';
import { RootState } from '../../../root-store/root-state';

@Injectable({
  providedIn: 'root',
})
export class PropertiesSelectService {
  selectedProperties = new BehaviorSubject<number[]>([]);

  _selectedProperties: number[];

  isOnlyView = new BehaviorSubject<boolean>(false);

  currentType = new BehaviorSubject<string>('multiple');

  /**
   * Small utility observable to get all the selected properties
   * with all of their data
   */
  propertiesChanges = combineProperties(
    this.selectedProperties,
    this.store.select(selectAllProperties),
  ).pipe(
    map(([ids, properties]: [number[], IProperty[]]) => properties),
    filter((properties) => !!properties.length),
    distinctUntilChanged((a, b) => isEqual(a, b)),
  );

  constructor(private store: Store<RootState>) {}

  update(propertiesIds: number[]): void {
    this._selectedProperties = propertiesIds;
    this.selectedProperties.next(propertiesIds);
  }
}
